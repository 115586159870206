<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/main_one.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>Adway.edu.kz арқылы Satbayev University-де онлайн білім алыңыз</h3>
        <p>Білімге, дамуға және жетістікке жету жолында бізге қосылыңыз!</p>
        <router-link to="/kz/#application" class="nav-link"><button>Кеңес алу</button></router-link>
        <router-link to="/kz/other/semesters" class="nav-link"><button>Қашықтықтан оқытуға өтініш беру</button></router-link>
      </div>
    </div>
    <div class="wrapper_one m-auto container" id="why">
      <div class="title">
        <h1>Артықшылықтар</h1>
        <hr>
      </div>
      <div class="wrap w-100 d-flex justify-content-between align-items-center">
        <div class="inner_card">
          <img src="@/assets/img/icon_4.png" alt="$">
          <div class="pt-3">
            <h3>Икемділік</h3>
            <p>Интернетке қосылым болған жағдайда кез келген уақытта және кез келген жерден оқуға болады. Бұл жұмыс істейтін адамдарға, ата-аналарға және шалғай аймақ тұрғындарына өте ыңғайлы.</p>
          </div>
        </div>
        <div class="inner_card">
          <img src="@/assets/img/icon_12.png" alt="$">
          <div class="pt-3">
            <h3>Қолжетімділік</h3>
            <p>Сабақтарды және оқу материалдарын 24/7 кез келген уақытта қайталап қарауға болады.</p>
          </div>
        </div>
        <div class="inner_card">
          <img src="@/assets/img/icon_7.png" style="width:64px;height:64px;" alt="$">
          <div class="pt-3">
            <h3>Ыңғайлылық</h3>
            <p>Сіз оқуды жұмыспен бірге үйлестіре аласыз (күні бойы жұмыс істеп, кешке вебинарларға қосылу).</p>
          </div>
        </div>
        <div class="inner_card">
          <img src="@/assets/img/icon_10.png" style="width:64px;height:64px;" alt="$">
          <div class="pt-3">
            <h3>Мемлекеттік диплом</h3>
            <p>Satbayev University-ден диплом алыңыз!</p>
          </div>
        </div>
      </div>

      <div class="mobile">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="20"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :loop="true"
            :navigation="{
              prevEl: prev1,
              nextEl: next1,
            }"
            class="mySwiper mySwiper1"
        >
          <swiper-slide>
            <div class="inner_card">
              <img src="@/assets/img/Icon1.png" alt="$">
              <div class="pt-3">
                <h3>Білім беру сапасы</h3>
                <p>Біз студенттерге заманауи білім мен дағдыларды меңгеруге мүмкіндік беретін, сарапшылар жасаған жоғары сапалы білім беру бағдарламаларын ұсынамыз.</p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner_card">
              <img src="@/assets/img/Icon2.png" alt="$">
              <div class="pt-3">
                <h3>Икемділік пен қолжетімділік</h3>
                <p>"Adway.edu.kz" платформасы икемді оқу кестесін, 24/7 материалдарға қолжетімділікті және студенттердің білім беру мақсаттарына жетуі үшін жеке қарқындылықты ұсынады.</p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner_card">
              <img src="@/assets/img/Icon3.png" alt="$">
              <div class="pt-3">
                <h3>Технологиялық ресурстар</h3>
                <p>Біз студенттердің білім алуын байыту үшін вебинарлар мен виртуалды сыныптарды қоса, заманауи технологиялар мен әдістерді қолданамыз.</p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner_card">
              <img src="@/assets/img/Icon4.png" alt="$">
              <div class="pt-3">
                <h3>Жеке қолдау</h3>
                <p>Біздің платформа білім беру үдерісінде студенттердің табысы мен дамуына қолдау көрсететін жеке ресурстарды ұсынады.</p>
              </div>
            </div>
          </swiper-slide>

          <!-- Навигационные стрелки -->
          <div ref="prev1" class="swiper-button-next"></div>
          <div ref="next1" class="swiper-button-prev"></div>
        </swiper>
      </div>
    </div>

    <div class="wrapper_two container pt-5 mb-3" id="process">
      <div class="title mb-5">
        <h1>Қабылдау үдерісі</h1>
        <hr>
      </div>
      <div class="wrap w-100 d-flex justify-content-between align-items-center">
        <div class="inner_card">
          <div class="card_inner">
            <div>
              <h1>1</h1>
            </div>
            <div class="pt-3">
              <h3>Өтініш беру</h3>
              <p>Өтініш формасын толтырып, бізде оқуды бастаңыз</p>
            </div>
          </div>
        </div>
        <div class="inner_card">
          <div class="card_inner">
            <div>
              <h1>2</h1>
            </div>
            <div class="pt-3">
              <h3>Менеджермен байланыс</h3>
              <p>Біздің менеджерлер сіздің сұрақтарыңызға жауап беруге көмектеседі</p>
            </div>
          </div>
        </div>
        <div class="inner_long_card">
          <img src="@/assets/img/main_two.png" alt="" style="position: absolute; right: 0; z-index: 10; top: 10px">
          <div class="card_inner">
            <div>
              <h1>3</h1>
            </div>
            <div class="pt-3">
              <h3>Satbayev University-де оқисыз</h3>
              <p>Satbayev University-ге қош келдіңіз!</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper_three container" id="applicants">
      <div class="title">
        <h1>Талапкерлерге қойылатын талаптар</h1>
        <hr>
      </div>
      <div class="pt-3 d-flex">
        <div class="icon" style="width: 40%">
          <img src="@/assets/img/main_five.png" alt="">
        </div>
        <div class="inner_card">
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>Жалпы орта білім туралы аттестат немесе
              техникалық білім дипломы</p>
          </div>
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>Жеке басын куәландыратын құжат көшірмесі (3 дана)</p>
          </div>
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>Әскери есепке алу учаскесіне тіркелу куәлігі (ер адамдар үшін, 2 дана)</p>
          </div>
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>3х4 см фото</p>
          </div>
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>ҰБТ сертификаты</p>
          </div>
        </div>
      </div>
      <div class="container m-auto long-img pt-2" id="bachelor_magistr">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :loop="true"
            :navigation="{
              prevEl: prev2,
              nextEl: next2,
            }"
            class="mySwiper mySwiper2"
        >
          <swiper-slide>
            <router-link to="/kz/program/computer-science">
              <img class="w-100 long-img-main" src="@/assets/img/main_three.png" alt="">
              <div class="long-img-mobile">
                <div class="icons">
                  <div class="inner_icons"><span>Магистратура</span></div>
                  <div class="inner_icons"><span>Бакалавр</span></div>
                </div>
                <div class="title">
                  <h3>Компьютерлік ғылымдар</h3>
                  <p>Компьютерлік ғылымдар бағдарламасы түлектерге IT, қаржы, денсаулық сақтау, маркетинг, деректер ғылымы және басқа да салаларда көптеген мансаптық мүмкіндіктер ашады.</p>
                </div>
                <img class="long-img-mobile-human" src="@/assets/img/man.png" alt="">
              </div>
            </router-link>
          </swiper-slide>
          <swiper-slide>
            <router-link to="/kz/program/it-security">
              <img class="w-100 long-img-main" src="@/assets/img/main_three2.png" alt="">
              <div class="long-img-mobile">
                <div class="icons">
                  <div class="inner_icons"><span>Магистратура</span></div>
                  <div class="inner_icons"><span>Бакалавр</span></div>
                </div>
                <div class="title">
                  <h3>Ақпараттық қауіпсіздік</h3>
                  <p>Деректер ғылымы бағдарламасы түлектерге IT, қаржы, денсаулық сақтау, маркетинг, деректер ғылымы және басқа да салаларда көптеген мансаптық мүмкіндіктер ашады.</p>
                </div>
                <img class="long-img-mobile-human" src="@/assets/img/woman.png" alt="">
              </div>
            </router-link>
          </swiper-slide>

          <!-- Навигационные стрелки -->
          <div ref="prev2" class="swiper-button-next"></div>
          <div ref="next2" class="swiper-button-prev"></div>
        </swiper>
      </div>
    </div>

    <div class="wrapper_four container py-5 mt-3" id="profession">
      <div class="title">
        <h1>Өзіңізге ұнайтын мамандықты таңдаңыз</h1>
        <hr>
      </div>
      <p class="py-3">Оқуды аяқтағаннан кейін, өз болашағыңызға мамандық таңдай аласыз.</p>
      <div class="inner_content">
        <div class="inner_card">
          <p>IT-маманы</p>
        </div>
        <div class="inner_card">
          <p>Web-бағдарламашы</p>
        </div>
        <div class="inner_card">
          <p>Деректер базасының әкімшісі</p>
        </div>
        <div class="inner_card">
          <p>Бизнес-талдаушы</p>
        </div>
        <div class="inner_card">
          <p>Интернет-жобалар менеджері</p>
        </div>
        <div class="inner_card">
          <p>Жоба менеджері</p>
        </div>
        <div class="inner_card">
          <p>Бағдарламашы</p>
        </div>
        <div class="inner_card">
          <p>Бағдарламалық жасақтама әзірлеуші</p>
        </div>
        <div class="inner_card">
          <p>Өнім менеджері</p>
        </div>
        <div class="inner_card">
          <p>Жүйелік әкімші</p>
        </div>
        <div class="inner_card">
          <p>Web және мультимедиялық қосымшалар әзірлеушісі</p>
        </div>
        <div class="inner_card">
          <p>Ақпараттық ресурстар маманы</p>
        </div>
        <div class="inner_card">
          <p>Ақпараттық жүйелер маманы</p>
        </div>
      </div>

      <Partners/>

      <div class="mobile">
        <div class="logos">
          <div class="logos-slide">
            <img src="@/assets/img/logo1.png" alt="Logo 1">
            <img src="@/assets/img/logo2.png" alt="Logo 2">
            <img src="@/assets/img/logo3.png" alt="Logo 2">
            <img src="@/assets/img/logo4.png" alt="Logo 2">
          </div>
        </div>
      </div>
    </div>

    <ContactForm/>
    <SemestersForm/>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ContactForm from "../../../components/kz/ContactForm";
import Partners from "../../../components/kz/Partners";
import SemestersForm from "../../../components/kz/SemestersForm";

export default {
  name: "landingPage",
  components: {
    ContactForm,
    Swiper,
    SwiperSlide,
    Partners,
    SemestersForm
  },
  data() {
    return {
      logos: [
        [
          "~@/assets/img/Logo1.png",
          "~@/assets/img/Logo2.png",
          "~@/assets/img/Logo3.png",
          "~@/assets/img/Logo4.png",
        ],
        [
          "~@/assets/img/Logo1.png",
          "~@/assets/img/Logo2.png",
          "~@/assets/img/Logo3.png",
          "~@/assets/img/Logo4.png",
        ]
      ],
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    const prev1 = ref(null);
    const next1 = ref(null);
    const prev2 = ref(null);
    const next2 = ref(null);

    onMounted(() => {
      const swiper1 = document.querySelector('.mySwiper1').swiper;
      swiper1.params.navigation.prevEl = prev1.value;
      swiper1.params.navigation.nextEl = next1.value;
      swiper1.navigation.init();
      swiper1.navigation.update();

      const swiper2 = document.querySelector('.mySwiper2').swiper;
      swiper2.params.navigation.prevEl = prev2.value;
      swiper2.params.navigation.nextEl = next2.value;
      swiper2.navigation.init();
      swiper2.navigation.update();
    });

    return {
      onSwiper,
      onSlideChange,
      prev1,
      next1,
      prev2,
      next2,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import './landing.scss';
</style>
