<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>Күндізгі оқу нысаны шеңберінде қашықтықтан оқытуға көшу</h3>
        <p style="margin-bottom: 25px;">Сәтбаев университетінің студенттеріне арналған</p>
        <router-link to="#semesters_form"><button>Өтінім жіберу</button></router-link>
      </div>
    </div>
    <div class="container px-5">
      <p><strong>Ыңғайлы оқыңыз – сіз қай жерде болсаңыз да! Қашықтықтан оқу – күндізгі бөлімнің барлық артықшылықтарымен бірге!</strong></p>

      <p>Құрметті студенттер!</p>

      <p>Енді сіз күндізгі бөлім студентінің барлық құқықтарын сақтай отырып, қашықтықтан оқуға ауыса аласыз, оның ішінде грант және академиялық қолдау мүмкіндіктері бар.</p>

      <p>Неліктен бұл тиімді?</p>
      <ul style="list-style: none;padding:0;">
        <li>✅ Көлік шығындарын үнемдеу – кептелістер мен артық шығындар жоқ.</li>
        <li>✅ Көбірек еркіндік – оқуды жұмыс немесе жобалармен қатар алып жүріңіз.</li>
        <li>✅ Ыңғайлылық – басқа қалада пәтер жалдаудың қажеті жоқ, кез келген жерден оқуға мүмкіндік бар.</li>
        <li>✅ Икемді кесте – білім сапасын сақтай отырып, өзіңізге қолайлы уақытта оқыңыз.</li>
      </ul>

      <p><b>Нұсқаулық</b></p>
      <ul style="list-style: none;padding:0;">
        <li>1. Өтінішті толтырып, қол қою – <a href="https://adway.edu.kz/theme/space/Obrazets-zayavleniya-na-GOG.docx">үлгіні жүктеу</a></li>
        <li>2. Өтінішті тіркеу</li>
        <li>3. Төлем түбіртегін тіркеу | <a href="https://kaspi.kz/pay/_gate?action=service_with_subservice&service_id=3025&subservice_id=23145&region_id=18">төлем сілтемесі</a> (айына 30 000 теңге)</li>
      </ul>

      <p class="d-flex justify-align-center" style="margin-bottom:60px;">
        <a href="https://forms.gle/imMcGSmYajvRKbWM6" class="btn btn-primary d-none">Жазғы семестрге <br/>өтініш беру</a>
        <a href="https://forms.gle/PenfGWQ8qTiJujsq8" class="btn btn-primary" style="margin-left:20px;">Күзгі семестріне<br/> өтініш беру</a>
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "SemestersPage",
}
</script>

<style lang="scss">
@import '../../landing/landing.scss';
</style>