<template>
  <div class="form d-flex flex-column justify-content-start align-items-start">
    <p>Тіркелетін тұлғаның аты-жөні (толық)</p>
    <input v-model="name" type="text" placeholder="Алмаз" required>

    <p class="pt-3">Байланыс мәліметтері</p>
    <input v-mask="'+7(###)###-##-##'" v-model="phone" type="text" placeholder="+7 (777) 777-77-77" required>

    <p class="pt-3">Студенттік білім беру бағдарламасының коды және атауы</p>
    <input v-model="code" type="text" placeholder="Код и наименование обучающийся образовательной программы" required>

    <p class="pt-3">Оқыту тілі</p>
    <input v-model="lang" type="text" placeholder="Язык обучения" required>

    <p class="pt-3">Ағымдағы оқу курсы</p>
    <input v-model="course" type="text" placeholder="Текущий курс обучения" required>

    <p class="pt-3">Тексеру (<a href="https://kaspi.kz/pay/_gate?action=service_with_subservice&service_id=3025&subservice_id=23145&region_id=18">Төлем сілтемесі</a>)</p>
    <input type="file" ref="file" @change="onChangeFileUpload"/>

    <p class="pt-3">Толтырылған және қол қойылған өтініш</p>
    <input type="file" ref="file1" @change="onChangeFileUpload1"/>

    <div class="mt-3 alert alert-warning">
      Егер сіз қандай да бір себептермен бағдарламаға қатыспауды шешсеңіз, оқу басталғанға дейін үш айдан кешіктірмей тіркеуден бас тартсаңыз, біз сіздің төлеміңізді толық қайтарамыз.
    </div>

    <button @click="submitForm" class="mt-5">Жіберу</button>
  </div>
</template>

<script>

export default {
  name: "SemesterFormComponent",
  props: {
    type: {
      type: String,
      required: true
    },
    from: {
      type: String,
      required: true
    }
  },
  data(){
    return {
      name: '',
      phone: '',
      code: '',
      lang: '',
      course: '',
      file: '',
      file1: '',
    };
  },
  methods: {
    onChangeFileUpload(event) {
      this.file = event.target.files[0];
      console.log("selected file", this.file);
    },
    onChangeFileUpload1(event) {
      this.file1 = event.target.files[0];
      console.log("selected file 1", this.file1);
    },
    async submitForm() {
      if(this.name=='' || this.phone=='' || this.code=='' || this.lang=='' || this.course=='' || this.type=='' || this.file=='' || this.file1==''){
        alert("Барлық өрістерді толтырыңыз");
      }
      else{
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('phone', this.phone);
        formData.append('code', this.code);
        formData.append('lang', this.lang);
        formData.append('course', this.course);
        formData.append('type', this.type);
        formData.append('from', this.from);
        formData.append('file', this.file);
        formData.append('file1', this.file1);

        try {
          const response = await fetch('https://adway.edu.kz/theme/space/ajax_semesters_form.php', { // Замените URL_API на фактический адрес вашего API
            method: 'POST',
            body: formData, // Используем FormData для отправки
          });
          const result = await response.json();
          if (result && result.result === 1) {
            alert('Пішін сәтті жіберілді: ' + result.msg);
          } else {
            throw new Error(result.msg);
          }
        } catch (error) {
          console.error('Ошибка при отправке формы: ', error);
          alert('Пішінді жіберу қатесі: ' + error.message);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form {
  width: 100%;
  margin-top: 30px;
  input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 100%;
    height: 58px;
    background: #F9F9F9;
    border: 1px solid #F3F3F3;
    border-radius: 16px;
  }
  input[type="checkbox"]{
    width: 20px;
    height: 20px;
    margin-right:10px;
  }
  p {
    font-family: Manrope, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #8E8E93;
  }
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    gap: 10px;
    width: 100%;
    height: 51px;
    background: #FFA51E;
    border-radius: 16px;
  }
}
</style>