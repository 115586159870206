<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>Переход на дистанционный формат обучения в рамках очного обучения</h3>
        <p style="margin-bottom: 25px;">для студентов ЮКУ им. Мухтара Ауэзова</p>
        <router-link to="#apply"><button>Отправить заявку</button></router-link>
      </div>
    </div>

    <div class="container">

      <section class="intro">
        <div class="container">
          <h2>Обучайся в ЮКУ им. Мухтара Ауэзова онлайн – учись удобно и экономь деньги!</h2>
          <p>Студенты ЮКУ им. Мухтара Ауэзова теперь могут учиться полностью онлайн! Это официальная возможность остаться на очной форме обучения, сохранить грант (при наличии) и при этом не тратить время и деньги на поездки в университет.</p>
        </div>
      </section>
      <section class="benefits">
        <div class="container">
          <h2>Почему это выгодно?</h2>
          <div class="grid">
            <div class="card">💰 <strong>Экономия</strong> Не тратьте деньги на проезд и жильё.</div>
            <div class="card">🕒 <strong>Больше свободы</strong> Учитесь в удобное время.</div>
            <div class="card">📚 <strong>Полноценное образование</strong> Все лекции, семинары и академподдержка.</div>
            <div class="card">🎓 <strong>Без риска</strong> Грант и стипендия сохраняется (при наличии).</div>
          </div>
        </div>
      </section>

      <section class="faq">
        <div class="container">
          <h2>Частые вопросы</h2>
          <div class="faq-item">❓ Это официально?</div>
          <div class="faq-answer">Да, предусмотрено регламентами ЮКУ имени Мухтара Ауезова.</div>
          <div class="faq-item">❓ Грант сохраняется?</div>
          <div class="faq-answer">Да, финансирование остаётся неизменным.</div>
          <div class="faq-item">❓ Как проходят занятия?</div>
          <div class="faq-answer">Обучение проходит на платформе Adway.edu.kz, где доступны все необходимые материалы и инструменты.
            <br>📚 Видеолекции – записанные лекции по предметам, которые можно просматривать в удобное время.
            <br>📝 Тестирования – контрольные задания и тесты с дедлайнами, которые помогают закрепить материал.
            <br>📂 Учебные материалы – преподаватели загружают конспекты, презентации и дополнительные ресурсы.
            <br>🎥 Видеосвязь с преподавателем – онлайн-занятия в прямом эфире, где можно задавать вопросы и участвовать в обсуждениях.
            <br>🏆 Рубежный контроль и сессия – проходят онлайн через платформу Adway.edu.kz в формате тестов, письменных работ или устных экзаменов по видеосвязи.
            <br>Все материалы доступны 24/7, но важно соблюдать дедлайны, чтобы успешно завершить обучение.</div>
          <div class="faq-item">❓ Будет ли отметка в дипломе?</div>
          <div class="faq-answer">Нет, изменений в дипломе не будет, так как данный формат является полностью очным.</div>
          <div class="faq-item">❓ Можно ли будет потом вернуться на оффлайн обучение?</div>
          <div class="faq-answer">Да, в таком же формате вы подаете заявку для перехода на оффлайн обучение</div>
        </div>
      </section>

      <section id="apply" class="apply">
        <div class="container">
          <h2>Как подать заявку?</h2>
          <ol>
            <li>📤 Выбрать дату начала обучения</li>
            <li>💳 Заполнить форму (заполнить заявление и оплатить)</li>
            <li>💳 Ожидать связи с менеджером перед началом обучения </li>
          </ol>
          <div class="buttons">
            <a href="https://forms.gle/HJFN5ocwzVUzofzXA" class="btn">Подача заявки на летний семестр</a>
            <a href="https://forms.gle/PW3nUJfDLY9wq81G7" class="btn">Подача заявки на обучение с 1 сентября</a>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
export default {
  name: "YukuSemestersPage"
}

document.addEventListener("DOMContentLoaded", function() {
  const questions = document.querySelectorAll(".faq-item");

  questions.forEach(question => {
    question.addEventListener("click", function() {
      const answer = this.nextElementSibling;

      // Закрываем все открытые ответы
      document.querySelectorAll(".faq-answer").forEach(ans => {
        ans.style.display = "none";
      });

      // Переключаем отображение ответа
      answer.style.display = answer.style.display === "block" ? "none" : "block";
    });
  });
});
</script>



<style lang="scss">
@import '../../landing/landing.scss';

.benefits, .faq, .apply {
  padding: 20px 20px;
  background: white;
  margin: 0px 0;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  text-align: center;
  margin: auto;
}
.card {
  background: #0056b3;
  color: white;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 18px;
  display: flex; /* Включаем flexbox */
  flex-direction: column; /* Выравниваем содержимое по вертикали */
  align-items: center; /* Центрируем текст по горизонтали */
  justify-content: center; /* Центрируем текст по вертикали */
  text-align: center; /* Выравнивание текста по центру */
  height: 150px; /* Фиксированная высота, можно регулировать */
}
.faq-item {
  background: #f1f1f1;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
}
.faq-answer {
  display: none;
  padding: 10px;
  background: white;
  border-radius: 5px;
  margin-top: 5px;
}
.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.buttons .btn {
  background: #0056b3;
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
}
</style>