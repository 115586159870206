<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>Дәстүрлі оқу аясында қашықтан оқыту форматына көшу</h3>
        <p style="margin-bottom: 25px;">Мухтар Ауэзов атындағы ЮКУ студенттері үшін</p>
        <router-link to="#apply"><button>Өтінім жіберу</button></router-link>
      </div>
    </div>
    <div class="container">
      <section class="intro">
        <div class="container">
          <h2>М. Әуезов атындағы ОҚУ-да онлайн оқы – ыңғайлы оқып, ақша үнемде!</h2>
          <p>М. Әуезов атындағы ОҚУ студенттері енді толықтай онлайн оқи алады! Бұл – күндізгі оқу формасында қалып, грантты (бар болған жағдайда) сақтап, университетке баруға уақыт пен қаражат жұмсамай оқу мүмкіндігі.</p>
        </div>
      </section>

      <section class="benefits">
        <div class="container">
          <h2>Неліктен бұл тиімді?</h2>
          <div class="grid">
            <div class="card">💰 <strong>Үнемдеу</strong> Көлік пен баспанаға ақша жұмсамаңыз.</div>
            <div class="card">🕒 <strong>Көбірек еркіндік</strong> Сізге ыңғайлы уақытта оқыңыз.</div>
            <div class="card">📚 <strong>Толыққанды білім</strong> Барлық дәрістер, семинарлар және академиялық қолдау сақталады.</div>
            <div class="card">🎓 <strong>Қауіпсіз</strong> Грант пен стипендия сақталады (бар болса).</div>
          </div>
        </div>
      </section>

      <section class="faq">
        <div class="container">
          <h2>Жиі қойылатын сұрақтар</h2>
          <div class="faq-item">❓ Бұл ресми ме?</div>
          <div class="faq-answer">Иә, бұл Мұхтар Әуезов атындағы ОҚУ-дың ресми ережелерімен бекітілген.</div>
          <div class="faq-item">❓ Грант сақтала ма?</div>
          <div class="faq-answer">Иә, қаржыландыру толық сақталады.</div>
          <div class="faq-item">❓ Оқу қалай өтеді?</div>
          <div class="faq-answer">Оқу **Adway.edu.kz** платформасында өтеді, мұнда барлық қажетті материалдар мен құралдар бар.<br>
            📚 Дәрістер – кез келген уақытта көруге болатын жазылған бейне-сабақтар.<br>
            📝 Тесттер – материалды бекіту үшін мерзімі белгіленген бақылау тапсырмалары.<br>
            📂 Оқу материалдары – оқытушылар конспектілер, презентациялар мен қосымша ресурстарды жүктейді.<br>
            🎥 Оқытушымен бейнебайланыс – тікелей эфирдегі онлайн-сабақтар, сұрақтар қою және пікір алмасу мүмкіндігі.<br>
            🏆 Аралық бақылау және сессия – **Adway.edu.kz** платформасы арқылы тесттер, жазбаша жұмыстар немесе бейнебайланыс арқылы емтихандар түрінде өтеді.<br>
            Барлық материалдар **24/7** қолжетімді, бірақ белгіленген мерзімдерді сақтау маңызды.</div>
          <div class="faq-item">❓ Дипломда белгі болады ма?</div>
          <div class="faq-answer">Жоқ, диплом өзгеріссіз қалады, өйткені бұл оқу формасы толықтай күндізгі оқу болып саналады.</div>
          <div class="faq-item">❓ Кейін офлайн оқуға көшуге бола ма?</div>
          <div class="faq-answer">Иә, дәл осы форматта офлайн оқуға ауысу үшін өтінім беруге болады.</div>
        </div>
      </section>

      <section id="apply" class="apply">
        <div class="container">
          <h2>Өтінімді қалай беруге болады?</h2>
          <ol>
            <li>📤 Оқудың басталу күнін таңдаңыз</li>
            <li>💳 Өтінімді толтырыңыз (арыз жазып, төлем жасаңыз)</li>
            <li>📞 Оқудың басталуына дейін менеджердің байланысын күтіңіз</li>
          </ol>
          <div class="buttons">
            <a href="https://forms.gle/T7mt8xwzuJgJof8b8" class="btn">Жазғы семестрге өтінім беру</a>
            <a href="https://forms.gle/7FxWCLwbmhriLXPx8" class="btn">1 қыркүйектен басталатын оқу үшін өтінім беру</a>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
export default {
  name: "YukuSemestersPage"
}

document.addEventListener("DOMContentLoaded", function() {
  const questions = document.querySelectorAll(".faq-item");

  questions.forEach(question => {
    question.addEventListener("click", function() {

      const answer = this.nextElementSibling;
      console.log("clicked", answer);

      // Закрываем все открытые ответы
      document.querySelectorAll(".faq-answer").forEach(ans => {
        console.log('ans', ans);
        ans.style.display = "none";
      });

      // Переключаем отображение ответа
      console.log("style before", answer.style.display);
      answer.style.display = answer.style.display === "block" ? "none" : "block";
      console.log("style", answer.style.display);
    });
  });
});
</script>

<style lang="scss">
@import '../../landing/landing.scss';
.benefits, .faq, .apply {
  padding: 20px 20px;
  background: white;
  margin: 0px 0;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  text-align: center;
  margin: auto;
}
.card {
  background: #0056b3;
  color: white;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 18px;
  display: flex; /* Включаем flexbox */
  flex-direction: column; /* Выравниваем содержимое по вертикали */
  align-items: center; /* Центрируем текст по горизонтали */
  justify-content: center; /* Центрируем текст по вертикали */
  text-align: center; /* Выравнивание текста по центру */
  height: 180px; /* Фиксированная высота, можно регулировать */
}
.faq-item {
  background: #f1f1f1;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
}
.faq-answer {
  display: none;
  padding: 10px;
  background: white;
  border-radius: 5px;
  margin-top: 5px;
}
.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.buttons .btn {
  background: #0056b3;
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
}
</style>
