<template>
  <div class="container" id="semesters_form">
    <div class="row">
      <div class="col-12 mb-3 semester_item d-none" id="leto" style="text-align:justify;">
        <p>Adway <b>жазғы семестр</b> курстарына тіркелуді ашады! Егер сізде академиялық қарыздар болса, сіз қайта оқуды немесе басқа университеттен ауысуды жоспарлап отырсыз – бұл сіздің оқуыңызды қажетсіз кедергісіз жалғастыру мүмкіндігіңіз.</p>
        <p>Құны: айына небәрі 30 000 теңге – сіздің ыңғайлылығыңыз бен болашағыңызға инвестиция.</p>
        <p>Оқытуды ыңғайлы етуге дайынсыз ба?</p>
        <p>Пішінді қазір толтырып, білім беру тәжірибесінің келесі деңгейіне қосылыңыз!</p>

        <SemesterForm type="Лето 2024-2025" :from="from"/>
      </div>

      <div class="col-12 mb-3 semester_item d-none" id="osen" style="text-align:justify;">
        <p>Adway <b>күзгі семестр</b> курстарына тіркелуді ашады!</p>
        <p>Құны: айына небәрі 30 000 теңге – сіздің ыңғайлылығыңыз бен болашағыңызға инвестиция.</p>
        <p>Оқытуды ыңғайлы етуге дайынсыз ба?</p>
        <p>Пішінді қазір толтырып, білім беру тәжірибесінің келесі деңгейіне қосылыңыз!</p>
        <SemesterForm type="Осень 2025-2026" :from="from"/>
      </div>
    </div>
  </div>
</template>

<script>
import SemesterForm from "./SemesterForm";
export default {
  name: "SemestersFormComponent",
  props: {
    from: {
      type: String,
      required: true
    }
  },
  components: {
    SemesterForm
  }
}
</script>
