<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>Переход на дистанционный формат обучения в рамках очного обучения</h3>
        <p style="margin-bottom: 25px;">для студентов Satbayev University</p>
        <router-link to="#semesters_form"><button>Отправить заявку</button></router-link>
      </div>
    </div>
    <div class="container px-5">
      <p><strong>Учись удобно, где бы ты ни был – дистанционное обучение с сохранением всех преимуществ очной формы!</strong></p>
      <p>Уважаемые студенты!</p>
      <p>Теперь у вас есть возможность перейти на дистанционное обучение, сохранив при этом все права студента очной формы, включая грант и академическую поддержку.</p>

      <p>Почему это выгодно?</p>
      <ul style="list-style: none;padding:0;">
        <li>✅ Экономия на транспорте – никаких пробок и лишних затрат.</li>
        <li>✅ Больше свободы – совмещайте учебу с работой или проектами.</li>
        <li>✅ Комфорт – учитесь из любого места, без необходимости снимать жильё в другом городе.</li>
        <li>✅ Гибкий график – обучайтесь в удобное время, сохраняя качество образования.</li>
      </ul>

      <p><b>Инструкция</b></p>
      <ul style="list-style: none;padding:0;">
        <li>1.	Заполнить заявление и подписать – <a href="https://adway.edu.kz/theme/space/Obrazets-zayavleniya-na-GOG.docx">скачать шаблон</a></li>
        <li>2.	Прикрепить заявление</li>
        <li>3.	Прикрепить чек | <a href="https://kaspi.kz/pay/_gate?action=service_with_subservice&service_id=3025&subservice_id=23145&region_id=18">ссылка на оплату</a> (30 000 ежемесячно)</li>
      </ul>

      <p class="d-flex justify-align-center" style="margin-bottom:60px;">
        <a href="https://forms.gle/imMcGSmYajvRKbWM6" class="btn btn-primary d-none">Подача заявки на летний<br/> семестр</a>
        <a href="https://forms.gle/PenfGWQ8qTiJujsq8" class="btn btn-primary" style="margin-left:20px;">Подача заявки на осенний<br/> семестр</a>
      </p>
    </div>

  </div>
</template>

<script>

export default {
  name: "SemestersPage",
}
</script>

<style lang="scss">
@import '../../landing/landing.scss';
</style>